import { ApiConfigurationOptions } from '../config'
import { CityDto } from '../models/city.dto'
import { CollectionResponse, EntityResponse } from './apiService'

export class CitiesApiService {
  constructor(
    private readonly apiConfiguration: ApiConfigurationOptions,
    private readonly acquireToken: () => Promise<string>
  ) {}

  async getCities(
    page: number,
    pageSize: number,
    sort: string | undefined,
    searchText: string | null
  ) {
    const token = await this.acquireToken()
    const res = await fetch(
      `${
        this.apiConfiguration.base
      }/gminy/search?pageSize=${pageSize}&page=${page}&sort=${
        sort ?? ''
      }&search=${searchText ?? ''}&variant=list`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const data = await res.json()
    return data as CollectionResponse<CityDto>
  }

  async updateCity(city: CityDto): Promise<CityDto | undefined> {
    const token = await this.acquireToken()
    const { id_gminy, ...newCity } = city
    const res = await fetch(`${this.apiConfiguration.base}/gminy/${id_gminy}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newCity),
    })

    const data = (await res.json()) as EntityResponse<CityDto>
    return data?.data
  }
}
