import { ApiConfigurationOptions } from '../config'
import { StreetDto } from '../models/street.dto'
import { CollectionResponse, EntityResponse } from './apiService'

export type StreetInParams = {
  cityId: number | undefined
}

export class StreetsApiService {
  constructor(
    private readonly apiConfiguration: ApiConfigurationOptions,
    private readonly acquireToken: () => Promise<string>
  ) {}

  async getStreets(
    page: number,
    pageSize: number,
    sort: string | undefined,
    searchText: string | null
  ) {
    const token = await this.acquireToken()
    const res = await fetch(
      `${
        this.apiConfiguration.base
      }/ulice/search?pageSize=${pageSize}&page=${page}&sort=${
        sort ?? ''
      }&search=${searchText ?? ''}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const data = await res.json()
    return data as CollectionResponse<StreetDto>
  }

  async getStreetsForCity(
    page: number,
    pageSize: number,
    sort: string | undefined,
    searchText: string | null,
    inParams?: StreetInParams
  ) {
    if (!inParams?.cityId) {
      return {
        items: [],
        type: 'ulice',
        totalPages: 0,
        totalItems: 0,
      }
    }

    const token = await this.acquireToken()
    const res = await fetch(
      `${this.apiConfiguration.base}/gminy/${
        inParams.cityId
      }/ulice/search?pageSize=${pageSize}&page=${page}&sort=${
        sort ?? ''
      }&search=${searchText ?? ''}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const data = await res.json()
    return data as CollectionResponse<StreetDto>
  }

  async updateStreet(street: StreetDto): Promise<StreetDto | undefined> {
    const token = await this.acquireToken()
    const { id_ulicy, ...newStreet } = street
    const res = await fetch(`${this.apiConfiguration.base}/ulica/${id_ulicy}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newStreet),
    })

    const data = (await res.json()) as EntityResponse<StreetDto>
    return data?.data
  }
}
