import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import React, { FC, PropsWithChildren } from 'react'
import config from '../config'

const msalInstance = new PublicClientApplication(config)

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

export const AzureAuthProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}
