import { AccountInfo, OIDC_DEFAULT_SCOPES } from '@azure/msal-browser'
import { useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react'
import { useCallback } from 'react'

export type AuthState = {
  isLoggedIn: boolean
  user: AccountInfo | null
  login: () => Promise<void>
  logout: () => Promise<void>
  acquireToken: () => Promise<string>
}

const useAuth = (): AuthState => {
  const { accounts, instance } = useMsal()
  const user = useAccount(accounts[0] || {})
  const isLoggedIn = useIsAuthenticated()

  const login = useCallback(() => instance.loginRedirect(), [instance])
  const logout = useCallback(() => instance.logoutRedirect(), [instance])
  const acquireToken = useCallback(async () => {
    const authResult = await instance.acquireTokenSilent({
      scopes: OIDC_DEFAULT_SCOPES,
      account: user ?? undefined,
    })
    return authResult?.accessToken
  }, [instance, user])

  return {
    user,
    isLoggedIn,
    login,
    logout,
    acquireToken,
  }
}

export default useAuth
