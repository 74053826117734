import { ApiConfigurationOptions } from '../config'
import { StreetTypeDto } from '../models/streetType.dto'
import { CollectionResponse } from './apiService'

export class StreetTypeApiService {
  constructor(
    private readonly apiConfiguration: ApiConfigurationOptions,
    private readonly acquireToken: () => Promise<string>
  ) {}

  async getStreetTypes(
    _page: number,
    _pageSize: number,
    _sort: string | undefined,
    _searchText: string | null
  ) {
    const token = await this.acquireToken()
    const res = await fetch(
      `${this.apiConfiguration.base}/tulic/search?pageSize=10&page=1&variant=list`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const data = await res.json()
    return data as CollectionResponse<StreetTypeDto>
  }
}
