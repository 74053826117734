import { Configuration } from '@azure/msal-browser'

export type ApiConfigurationOptions = {
  base: string
}

export type ApiConfiguration = {
  api: ApiConfigurationOptions
}

export type AppConfiguration = Configuration & ApiConfiguration

export default {
  basename: '', // only at build time to set, like 'demos/admin-templates/materially/react/default'
  theme: 'light', // 'light', 'dark',
  rtlLayout: false,
  i18n: 'en',
  jwt: {
    secret: 'SECRET-KEY',
    timeout: '1 days',
  },
  firebase: {
    apiKey: 'AIzaSyD4NTMxu5a-E5aLF3bcka0wEj2SMQ-uRIg',
    authDomain: 'materially-36914.firebaseapp.com',
    projectId: 'materially-36914',
    storageBucket: 'materially-36914.appspot.com',
    messagingSenderId: '478479518287',
    appId: '1:478479518287:web:aa68a391320608737ff83a',
    measurementId: 'G-ZRET4JXFZH',
  },
  auth0: {
    client_id: 'HvYn25WaEHb7v5PBT7cTYe98XATStX3r',
    domain: 'demo-localhost.us.auth0.com',
  },
  auth: {
    clientId: 'ac186bb1-9742-4fe1-b870-c788df1b434c',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  api: {
    base: 'https://serwer2213207.home.pl/apiplqa',
  },
} as AppConfiguration
