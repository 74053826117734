import React, { createContext, FC, PropsWithChildren, useMemo } from 'react'
import useAuth from '../hooks/useAuth'
import { StreetsApiService } from '../services/streetsApiService'
import { CitiesApiService } from '../services/citiesApiService'
import config from '../config'
import { StreetTypeApiService } from '../services/streetTypeApiService'

export type ApiServicesContextType = {
  streetsService: StreetsApiService
  streetTypesService: StreetTypeApiService
  citiesService: CitiesApiService
}

export const ApiServicesContext = createContext<ApiServicesContextType>({
  streetsService: new StreetsApiService(config.api, () => Promise.resolve('')),
  streetTypesService: new StreetTypeApiService(config.api, () =>
    Promise.resolve('')
  ),
  citiesService: new CitiesApiService(config.api, () => Promise.resolve('')),
})

export const ApiServicesContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { acquireToken } = useAuth()
  const streetsApiService = useMemo(
    () => new StreetsApiService(config.api, acquireToken),
    [acquireToken]
  )
  const streetTypesApiService = useMemo(
    () => new StreetTypeApiService(config.api, acquireToken),
    [acquireToken]
  )
  const citiesApiService = useMemo(
    () => new CitiesApiService(config.api, acquireToken),
    [acquireToken]
  )
  return (
    <ApiServicesContext.Provider
      value={{
        streetsService: streetsApiService,
        streetTypesService: streetTypesApiService,
        citiesService: citiesApiService,
      }}
    >
      {children}
    </ApiServicesContext.Provider>
  )
}
