import { ReactElement, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

type NavigationScrollProps = RouteComponentProps & {
  children: ReactElement
  location: { pathname: string }
}

const NavigationScroll = ({
  children,
  location: { pathname },
}: NavigationScrollProps) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return children || null
}

export default withRouter(NavigationScroll)
